/*include"common/help/glossary.css"*/

.pageHelpModal
{
	min-width: 600px;
	max-width: 600px;
	min-height: 280px;
	max-height: 420px;
	position : absolute;
	z-index : 10002;
	overflow: visible;
	display: none;
}

.pageHelpContent
{
	height: auto;
	background: url("/platform/common/help/background.png");
	background-repeat:repeat-x;
	padding: 13px;
	background-color: #FFFFFF;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid grey;
	font-family: Arial;
	font-size: 13px;
	color: #434343;
}

.pageHelpContentHtml
{
	min-height: 170px;
	max-height: 310px;
	overflow: auto;
	font-size: 11px;
}

.pageHelpContentHtml ul
{
	line-height: 1em;
	margin-top: 0.5em;
	margin-bottom: 0em;
}

.pageHelpContentHtml ol
{
	line-height: 1em;
	margin-top: 0.5em;
	margin-bottom: 0em;
}

.pageHelpHeader
{
	background-color: #000066;
	padding: 5px;
	color: white;
	font-size: 16px;
	font-weight: bold;
	vertical-align: middle;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	vertical-align: middle;
	cursor: move;
}

.pageHelpFooter
{
	margin-bottom: 3px;
	height: 15px;
}

.pageHelpFooterContainer
{
	float: right;
	display: inline;
}

.pageHelpFooterElement
{
	vertical-align: middle;
	display: inline;
}

.hideAutoHelp
{
	margin-top: 4px;
	height: inherit;
	color: #6B6B6B;
	font-family: Arial;
	font-size: 11px;
	float: right;
	display: inline;
}
