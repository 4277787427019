.backgroundPopUpView
{
	overflow: hidden;
}

.modalErrorBlock
{
	font-weight:bold;
	padding:4px;
	background-color: #f9f9f9;
	border: 1px solid #f2f2f2;
	color:red;
	display:none;
	font-size:8pt;
	margin-bottom:5px;
}

.modalHeader
{
	background-color: #000066;
	padding: 5px;
	color: white;
	font-size: 16pt;
	font-weight: bold;
	vertical-align: middle;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	cursor: move;
}

.modalHeaderSlide
{
	background-color: #6699FF;
	padding: 5px;
	color: white;
	font-size: 16pt;
	font-weight: bold;
	vertical-align: middle;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.modalContent
{
	background-color: white;
	padding: 20px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid grey;
}

.modalContentNoPadding
{
	background-color: white;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid grey;
}

.modalClose
{
	position: relative;
	right:11px;
	top:7px;
	color:white;
	min-width:16px;
	min-height:16px;
	cursor:pointer;
	font-weight: bold;
	font-size: 8pt;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABBUlEQVR4nNVTwWqDQBR8qSBCIX/Q0k8oCf2ANvdALx7aLzRHr36ABPTkSYpKDqLmWHA3TzdMD9V0azSX0EMH3mV4M4+ZZWcA6BrcXKX+EwPP8+ZZli2HfFEUyzRN788cAJzGdd05gC0AVkqte76u6xWAGsAuz/MHXfPLIAzDJwCMb7BSai2E6MUA0EopXyYNumuvmkkD4NCLmdke7p8ZaCYtfqDGxAAmX+GTiI56VYZhHEY3R67rmY/dnDq5GCGO44VeGDPbUkq76wIAOEmSx8kIZVnuiWhPRKppmnfTNDeWZW2EEG9E1BLRNoqij4sRfN+/q6rqecgHQbBwHOd2yM/+/2f6AkomlDaZw4T5AAAAAElFTkSuQmCC);
	background-repeat: no-repeat;
	float:right;
}

.modalInformation
{
	position: relative;
	right:15px;
	top:7px;
	min-width:16px;
	min-height:16px;
	color:white;
	cursor:pointer;
	font-weight: bold;
	font-size: 8pt;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAA2lJREFUOE+FkgtMk2cUhv9LCz+tlNJWNgZKUS4K4hV0GxolGAxKZIQNLxhBU+hk0A6VqejCGioWoxgEohhhLiCdQzsElIsQ8BJoJ1etwCDikLlNR9AVrbW+afa3cSZmJL7JSU7e7z1PvnzfIYlpJHBzpwgnIY9mBDz7MSymF6T12UvTP09t0+XfenxXN8o7OCIgVlGUn17Srs+uHnh84MK9P1MLW26t21WQ5zlvtb89My1EIBRToTHpm5Rn9UM59ePW3ObHULf+jVy2VE1/4VDtmCWl5NZASJQ83tVN9C7EhTeDDIpI3qD8oX/im58fIrvuIQ43PMCR+iForo4gt8Hu/Y69l36D7EzPE7/wxHUMO/P2Jl7+oR8kqOsNu86PQHmJDV/oxcgfk5gwmR1lGByHptaIjJ8eQF4xjJhDNXrPOUskDgCX60QErk2XbTnZ9Tz53DDkP44h/fvbKGvsRmVrLzqNo7C+hu3G3THIyu8gqfxXfF5gMPmuTk3icLgEIRBK6KVbj5V+VtBj3VQ6iG3n7mNH5SgLGoWy6g5q9SN4ZX1ta+waxdbTfUg4NYCNx7qtC77QlLgK2Lfg8CTOIduKdWtyO7EmvxdrTxgRXTKEzaf7Uacftj2dMttau+8jrawLscVGrC/oR6Raj+AthdUcF7ETQTPuXJ/Yo9qFWW0I/VaPT9Q9WKXpw56KPky9eGnrHHxkizve4YCvyuvBpyoDQve3Qxp7pIJ2FnIJxoVPSlZm5vh/1WAJybqOpQc7sTxHD2WZAeNPnqG8ZRgrVL8g7LvbWJzdgQV72+GfdtXiEa7MdmZ4JEHTNMH3jVwxO6nqUWDGNQTvacfCfdcRrboGjbYD8uI2LNln924iaHcbAjKa4ZusHZ8hjVhGUW/WgS/80EkcvjtPKrts8UtrwvyMJihONmNi0mTTtd3FIkUdAhQtmJvWCKmsxuKxMlPFE3iwX/BGNM0hGHGgZGaUutp7h84sTanHx0odvi5qQszBGsxJrYNPyhX47NSZP4o+rHURB4jtM+/IARH5idyWpWR6xpcavRK1k97bL1q8tl80eydWTcxKODMgDpMpGNFc9/8N/0eiKJpg+CKa8VjkI5gfHycKk2eJl3+ZJQyKj2O92QzfnbZn3iuSpAia3TKS4rItl3T0rDed/gU2gZwYU0MTeQAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	float:right;
}

.modalBackgroundPopup
{
	display : none;
	position : fixed;
	height : 100%;
	width : 100%;
	top : 0px;
	right : 0px;
	bottom : 0px;
	left : 0px;
	z-index : 4900;
	opacity : 0.4;
	filter : alpha(opacity=40);
	background-color : #333333;
	background-image : none;
	background-repeat : repeat;
}

.modalDivParent
{
	position : absolute;
	max-width:100%;
	z-index : 4901;
	visibility : visible;
	display : block;
	padding : 0px;
	padding-top : 0px;
}

.modalDetailsContainer
{
	padding: 5px 5px 2px 5px;
}

.modalDetailsContainerTable
{
	color: #989898;
}

.modalDetailsContainerTableLeadColumn
{
	white-space: nowrap;
}


.modalDetailsContainerLeadText
{
	float: left;
}

.modalContentsContainer
{
	margin-right: 5px;
	resize: none;
}

.modalPreviewContents
{
	color: #999999;
	background-color: #F4F4F4;
	border: 1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin: 0px 0px 12px 0px;
	resize: none;
	width: 99%;
}

.modalErrorContainer
{
	background-color: #FFFECB;
	border: 1px solid #B5B5B5;
	border-radius: 5px;
	padding: 3px 6px;
	min-height: 70px;
	font-size: 11px;
	color: #5E5E5E;
}

.modalErrorContainerIcon
{
	float: left;
}

.modalErrorContainerHeader
{
	float: left;
	font-size: 14px;
	padding-top: 2px;
}

.modalErrorContainerContents
{
	margin-left: 40px;
	padding-top: 22px;
}
