.headerizerHeading
{
	height:30px;
	width:auto;
	margin-top:15px;
	margin-bottom:2px;
	color:#fff;
	background-color:#949494;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border:1px solid #797979;
	padding: 7px 5px 0px 5px;
	font-size:large;
}

.headerizerHeadingLight
{
	height: 30px;
	width: auto;
	margin-top: 10px;
	color: #000000;
	padding: 7px 5px 0px 5px;
	font-size: large;
	font-weight: bold;
}

.headerizerHeadingCollapsibleContent
{
	float:        right;
	padding-right: 10px;
}

.headerizerHeadingSubHeadingLight
{
	height:18px;
	color:#000000;
	margin-bottom: 5px;
	margin-top: 5px;
	padding: 3px 5px 0px 5px;
	font-size: 11px;
	font-weight: bold;
}

.headerizerHeadingTable
{
	height:12px;
	color:#414141;
	background-color:#E2E2E2;
	padding: 8px 5px 6px 10px;
	font-family: Arial, bitstream vera sans, Tahoma;
	font-size: 10px;
	font-weight: bold;
}

.largeSizeFont
{
	font-size: large;
}

.mediumSizeFont
{
	font-size: medium;
}

.smallSizeFont
{
	font-size: small;
}

.headerizerHeadingCollapsible
{
	background-color: #949494;
}

.headerizerHeadingCollapsible:hover
{
	background-color: #7b7b7b;
	cursor: pointer;
}

.headerizerHeadingTitle
{
	float:left;
}

.headerizerHeadingControlBar
{
	width:auto;
	height:inherit;
	float:right;
}

.headerizerHeadingSubHeading
{
	height:18px;
	color:#666666;
	background-color:#e6e6e6;
	margin-bottom: 3px;
	margin-top: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 3px 5px 0px 5px;
	font-size: 11px;
	font-weight: bold;
}

.headerizerHeadingSubHeading:hover
{
	background-color:#d6d6d6;
}

.headerizerHeadingTable
{
	height:12px;
	color:#414141;
	background-color:#E2E2E2;
	padding: 8px 5px 6px 10px;
	font-family: Arial, bitstream vera sans, Tahoma;
	font-size: 10px;
	font-weight: bold;
}
