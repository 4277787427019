body {
	background: #ececec;
}

form.formular, .validationEngineContainer {
	font-family: tahoma, verdana, "sans-serif";
	font-size: 12px;
	padding: 20px;
	border: 1px solid #A5A8B8;
	width: 300px;
	margin: 0 auto;
}
.formular fieldset {
	margin-top: 20px;
	padding: 15px;
	border: 1px solid #B5B8C8;
}

.formular legend {
	font-size: 12px;
	color: #15428B;
	font-weight: 900;
}

.formular fieldset label {
	float: none;
	text-align: inherit;
	display:block;
	width: auto;
}

.formular label span {
	color: #000;
}

.formular input,.formular select,.formular textarea {
	display: block;  /* this breaks relative positioning */
	margin-bottom: 5px;
}

.formular .text-input {
	width: 250px;
	color: #555;
	padding: 4px;
	border: 1px solid #B5B8C8;
	font-size: 14px;
	margin-top: 4px;
	background: #FFF repeat-x;
}

.formular textarea {
	width: 250px;
	height: 70px;
	color: #555;
	padding: 4px;
	border: 1px solid #B5B8C8;
	font-size: 14px;
	margin-top: 4px;
	background: #FFF repeat-x;
}

.formular .infos {
	background: #FFF;
	color: #333;
	font-size: 12px;
	padding: 10px;
	margin-bottom: 10px;
}

.formular span.checkbox, .formular .checkbox, .formular .radio {
	display: inline;
}

.formular .submit {

	border: 1px solid #AAA;
	padding: 4px;
	margin-top: 20px;
	float: right;
	text-decoration: none;
	cursor: pointer;
}

.formular hr {
	clear: both;
	visibility: hidden;
}

.formular .fc-error {
	width: 350px;
	color: #555;
	padding: 4px;
	border: 1px solid #B5B8C8;
	font-size: 12px;
	margin-bottom: 15px;
	background: #FFEAEA;
}

.formular input[data-prompt-position="inline"] {
	display: inline;
}

.footnote {
	font-size: 11px;
	color: #666;
	font-style: italic;
}

.prompt-target {
	border: 1px dotted #666;
	min-height: 21px;
}

.inline-prompts label>span{
	display: inline-block;
	width: 250px;
}

.inline-prompts .text-input+.formError{
	margin-left: 10px;
}

.prompt-summary .inline {
	display: block;
}